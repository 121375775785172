<template>
  <div>
    <basic-subheader>
      <template v-slot:filters>
        <b-row>
          <div class="p-4 mt-4">
            <basic-input
              label="Tên khách hàng"
              placeholder="Nhập tên khách hàng"
              name="name"
              class="route-input"
              :value.sync="filter.name"
              :debounce="500"
              @v-modal:update="searchDebounce"
            ></basic-input>
          </div>
          <!-- <div class="p-4 mt-4">
            <label for="createdtime">Ngày thêm</label>
            <date-picker
              :range="true"
              :clearable="false"
              input-class="form-control"
              type="date"
              format="DD/MM/YYYY"
              value-type="date"
              @change="searchDate"
              v-model="filter.createDatetime"
            />
          </div> -->
        </b-row>
      </template>
      <template v-slot:actions>
        <b-button
          v-if="isWriteAllPermission || isWriteOwnerPermission"
          class="btn btn-success mr-2"
          @click="importCustomer"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/File/file-download.svg" />
          </span>
          Import
        </b-button>
        <b-button
          v-if="isWriteAllPermission || isWriteOwnerPermission"
          class="btn btn-success"
          type="button"
          @click.stop="createCustomerReceive"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm mới
        </b-button>
        <modal-import @import-success="loadData(true)" />
      </template>
    </basic-subheader>
    <b-overlay>
      <template>
        <b-row>
          <b-col>
            <div class="card card-custom gutter-b">
              <div class="card-body mt-0">
                <template-table
                  :column="column"
                  :data="data"
                  :paging="paging"
                  :tableAction="false"
                  :searchAction="false"
                  :selectAction="false"
                  @search="searchRequest"
                  @reset="resetRequest"
                  @sortBy="sortRequest"
                >
                  <template v-slot:body="{ item }">
                    <td class="text-left mw-150">
                      {{ item.name }}
                    </td>
                    <td style="width: 20px">
                      <action-dropdown
                        :value="item"
                        :show_copy="false"
                        :show_delete="false"
                        :show_edit="false"
                        :show_view="false"
                      >
                        <template>
                          <b-dropdown-text
                            tag="div"
                            class="navi-item cursor-pointer"
                            @click="detailCustomer(item)"
                          >
                            <a class="navi-link text-primary">
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/eye.svg"
                                />
                              </span>
                              <span class="navi-text text-primary"
                                >Xem chi tiết</span
                              >
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-text
                            tag="div"
                            class="navi-item cursor-pointer"
                            @click="deleteCustomer(item)"
                          >
                            <a class="navi-link text-danger">
                              <span class="menu-icon svg-icon svg-icon-sm">
                                <inline-svg
                                  class="svg-icon"
                                  src="/media/svg/icons/Neolex/Basic/power.svg"
                                />
                              </span>
                              <span class="navi-text text-danger"
                                >Xóa dữ liệu</span
                              >
                            </a>
                          </b-dropdown-text>
                        </template>
                      </action-dropdown>
                    </td>
                    <td class="text-center">{{ item.phoneNumber }}</td>
                    <td
                      class="text-center w-40 cursor-pointer"
                      @click="handleCall(item)"
                    >
                      <span class="svg-icon">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Call/call-phone.svg"
                        />
                      </span>
                    </td>
                    <td class="text-center">
                      {{
                        item.gender === 0
                          ? 'Nam'
                          : item.gender === 1
                          ? 'Nữ'
                          : 'Khác'
                      }}
                    </td>
                    <td class="text-center">
                      {{ item.birthYear }}
                    </td>
                    <td class="text-center mw-150">
                      {{ item.contactSource }}
                    </td>
                    <td class="text-center mw-150" style="width: 30px">
                      {{ getNameOf('coach', item.coachId) }}
                    </td>
                    <td class="text-center">
                      <a
                        @click="changeReasonOutItem(item)"
                        class="cursor-pointer text-black"
                      >
                        {{ item.reasonOut || 'Chưa có ghi chú' }}
                      </a>
                    </td>
                    <td>
                    {{ getPackageName(item.packageId) }}
                </td>
                 <td>
                    {{ getPackageCode(item.packageId) }}
                </td>
                    <td>
                      <div class="d-flex flex-column ml-5">
                        <a @click="confirmItem(item)" class="cursor-pointer">
                          <p
                            class="mb-0 module-list-page__body__updater-name text-center confirm"
                          >
                            {{ statuses[1].text }}
                          </p>
                        </a>
                        <a
                          @click="moveToPotentialItem(item)"
                          class="cursor-pointer"
                        >
                          <p
                            class="mt-2 mb-0 module-list-page__body__updater-code text-center refuse"
                          >
                            {{ statuses[0].text }}
                          </p>
                        </a>
                      </div>
                    </td>
            
                  </template>
                </template-table>
              </div>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-overlay>
    <call-client :targetItem="currentItem" @load-data="loadData" />
    <reason-question
      :targetItem="currentItem"
      :title="currentTitle"
      @load-data="loadData"
    />
    <modal-detail :targetItem="currentItem" @load-data="loadData" :packagesName="packagesName"/>
    <modal-accept :targetItem="currentItem" @load-data="loadData" />
    <modal-create @load-data="loadData" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import DatePicker from 'vue2-datepicker';
import moment from 'moment-timezone';
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('customerReceives');

export default {
  props: {
   
  },
  name: 'CustomerReceiveTab',
  components: {
  //  'date-picker': DatePicker,
    'call-client': () => import('./CallModal.vue'),
    'reason-question': () => import('./ReasonModal.vue'),
    'modal-import': () => import('./ImportModal.vue'),
    'modal-create': () => import('./CreateModal.vue'),
    'modal-accept': () => import('./AcceptModal.vue'),
    'modal-detail': () => import('./DetailModal.vue'),
  },
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      data: [],
      statuses: [
        {
          text: 'Từ chối',
          value: 0,
        },
        {
          text: 'Xác nhận tham gia',
          value: 1,
        },
      ],
      coaches: [],
      courses: [],
      genders: [],
      sort: {
        by: null,
        order: null,
      },
      column: [
        {
          key: 'name',
          label: 'Khách hàng',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: '',
          label: '',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'phoneNumber',
          label: 'Số điện thoại',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'call',
          label: '',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'gender',
          label: 'Giới tính',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'birthYear',
          label: 'Năm sinh',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'contactSource',
          label: 'Nguồn tiếp nhận',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'coach',
          label: 'Coach phụ trách',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'reasonOut',
          label: 'Ghi chú',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'packageName',
          label: 'Gói dịch vụ',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'packageCode',
          label: 'Mã gói',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
        {
          key: 'action',
          label: 'Hành động',
          sortable: false,
          style: {
            'text-align': 'center',
          },
        },
      ],
      filter: {
        name: null,
        phoneNumber: null,
        gender: null,
        birthYear: null,
        contactSource: null,
        status: 0,
        coachId: null,
        reasonOut: null,
        createDatetime: [
          moment().format('MM/DD/YYYY'),
          moment().format('MM/DD/YYYY'),
        ],
      },
      currentItem: {},
      currentTitle: null,
      packagesName: [],
      packageCode:null,
      packageName:null,
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    isAdmin() {
      let roles = this.currentUser?.roles;
      if (roles && roles.length) {
        return roles.includes('Admin');
      } else {
        return false;
      }
    },
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWriteOwnerPermission() {
      return this.$route.meta.isWriteOwnerPermission;
    },
    searchParams() {
      const payload = this.removeEmpty({
        name: this.filter.name,
        status: this.filter.status,
        genders: this.filter.genders,
        coachId: this.filter.coachId,
        contactSource: this.filter.contactSource,
        page: this.paging.page,
        size: this.paging.pageSize,
        startDate: moment(this.filter.createDatetime[0]).format('MM/DD/YYYY'),
        dueDate: moment(this.filter.createDatetime[1]).format('MM/DD/YYYY'),
      });
      const params = new URLSearchParams();
      for (const property in payload) {
        params.append(property, payload[property]);
      }
      return params;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
    // 'getPackageName'(val) {
    //   console.log('packagesName', val);
    // },
  },
  methods: {
    ...mapActions(['setCourses', 'setCoaches']),
    searchRequest() {
      this.loadData();
      document.body.click();
    },
    getPackageName(item)
    {
      var result = this.packagesName.find(p => p.id === item)
      this.packageName = result.name
      return result.name;
    },
    getPackageCode(item)
    {
      var result = this.packagesName.find(p => p.id === item)
      this.packageCode = result.code
      return result.code;
    },
    resetRequest() {
      this.filter.name = null;
      this.filter.phoneNumber = null;
      this.filter.gender = null;
      this.filter.status = null;
      this.filter.coachId = null;
      this.filter.contactSource = null;

      this.loadData();
      document.body.click();
    },
    sortRequest(sortData) {
      this.sort = {
        by: sortData.column,
        order: sortData.order,
      };
      this.loadData();
    },
    createCustomerReceive() {
      this.$bvModal.show('create-modal');
    },
    async loadData() {
      try {
        this.$store.commit('context/setLoading', true);
        const { data, meta } = await this.$api.get('CustomerReceives', {
          params: this.searchParams,
        });

        const filteredData = data?.filter((el) => el.status === 0) || [];

        this.data =
          filteredData?.map((el) => {
            return {
              ...el,
            };
          }) || [];
        this.paging.total = meta.total;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    async getSearchDataPrepare() {
      await this.$api
        .get('CustomerReceives/PrepareSearchFormItem')
        .then(({ data }) => {
          const { coaches } = data;
          this.coaches = coaches?.map((c) => {
            return {
              text: c?.coachName,
              value: c?.coachId,
            };
          });
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
          return Promise.reject(error);
        });
    },
    async getDataCourses() {
      await this.$api
        .get('Courses')
        .then(({ data }) => {
          const items = data.items.filter((x) => x.status === 0);
          this.courses = items?.map((c) => {
            return {
              text: c?.name,
              value: c?.id,
              startDatetime: c?.startDatetime,
              endDatetime: c?.endDatetime,
            };
          });
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
          return Promise.reject(error);
        });
    },
    handleCall(item) {
      this.currentItem = item;
      this.$bvModal.show('call-modal');
    },
    async changeStatus(item) {
      this.$store.commit('context/setLoading', true);
      // Create a new FormData object
      const formData = new FormData();

      // Append properties of the item object to formData
      Object.keys(item).forEach((key) => {
        if (key == 'isDeleted') {
          formData.append('isDeleted', !!item.isDeleted);
        } else formData.append(key, item[key]);
      });

      try {
        await this.$api.put(`/CustomerReceives/${item.id}`, formData);
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
      } catch (error) {
        this.$store.commit('context/setLoading', false);
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
      this.loadData();
    },
    searchDebounce(value) {
      this.filter.name = value;
      this.resetPaging();
    },
    searchDate() {
      if (
        this.filter.createDatetime[0] == null &&
        this.filter.createDatetime[1] == null
      ) {
        this.filter.createDatetime = [
          moment().format('MM/DD/YYYY'),
          moment().format('MM/DD/YYYY'),
        ];
      }
      this.resetPaging();
    },
    resetPaging() {
      this.paging.page = 1;
      this.loadData();
    },
    importCustomer() {
      this.$bvModal.show('modal-import');
    },
    confirmItem(item) {
      this.currentItem = { ...item, packageName: this.packageName ,packageCode: this.packageCode };
      this.$bvModal.show('accept-modal');
    },
    moveToPotentialItem(item) {
      this.currentItem = item;
      this.currentTitle = 'Lý do chuyển người dùng thành khách hàng tiềm năng';
      this.$bvModal.show('reason-modal');
    },
    getNameOf(key, value) {
      switch (key) {
        case 'coach':
          return (
            this.coaches?.find((item) => item.value == value)?.text ?? null
          );
      }
    },
    changeReasonOutItem(item) {
      this.currentItem = item;
      this.currentTitle = 'Ghi chú';
      this.$bvModal.show('reason-modal');
    },
    deleteCustomer(item) {
      this.$swal({
        title: '',
        text: `Bạn có chắc muốn xóa khách hàng này không?`,
        icon: '/media/svg/icons/SweetAlert/alert-triangle-red.svg',
        buttons: {
          cancel: {
            text: 'Quay lại',
            value: false,
            visible: true,
            className: 'btn btn-secondary',
            closeModal: true,
          },
          confirm: {
            text: `Xóa`,
            value: true,
            visible: true,
            className: `btn btn-inactive`,
            closeModal: true,
          },
        },
      }).then(
        async function (result) {
          if (result) {
            await this.$api.delete(`CustomerReceives/${item.id}`).then(() => {
              this.loadData();
              this.$toastr.s({
                title: 'Thành công !',
                msg: 'Xóa khách hàng tham gia thành công.',
              });
            });
          }
        }.bind(this),
      );
    },
    detailCustomer(item) {
      this.currentItem = item;
      this.$bvModal.show('customer-receive-detail-modal');
    },
    async GetPackage() {
      await this.$api
        .get('/Package')
        .then((response) => {
          this.packagesName = response.data;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    },
  },

  async created() {
    await Promise.all([
      this.loadData(),
      this.getSearchDataPrepare(),
      this.getDataCourses(),
      this.GetPackage(),
    ])
      .then(() => {
        this.setCourses(this.courses);
        this.setCoaches(this.coaches);
      })
      .catch(() => {});
  },
};
</script>

<style lang="scss" scoped>
.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  width: 96px;
  text-align: center;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #00c7f9;
  }

  &.warning-color {
    background-color: #ff5756;
  }
}

.customer-list-page__body__tabs::v-deep {
  margin: 0 -2rem -2rem;

  a.nav-link {
    border: 0;

    &.active {
      font-weight: 600;
      color: #01645a !important;
      border-bottom: 2px solid #01645a;
    }
  }

  li.nav-item {
    a.nav-link:hover {
      color: #333;
    }
  }

  .tab-content {
    display: none;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-form-filter {
  .dropdown-menu {
    .container {
      width: 850px;
    }
  }
}

.search-input {
  min-width: 220px;
  max-width: 220px;
}

.mw-400 {
  min-width: 400px;
  max-width: 400px;
}

.mw-150 {
  min-width: 150px;
  max-width: 150px;
}

.mw-200 {
  min-width: 200px;
  max-width: 200px;
}

.mw-250 {
  min-width: 250px;
  max-width: 250px;
}

.w-40 {
  width: 40px;
}

.mw-157 {
  width: 157px;
}

.confirm {
  color: #003af9;
}

.refuse {
  color: #ff5756;
}

.text-black {
  color: #333;
}
</style>
